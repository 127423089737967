import {useContext} from 'react'

import Script from 'next/script'

import {StoryComponentContext} from '../../story/StoryComponent'

export default function GAViewedStory() {
  const {story, url: storyUrl} = useContext(StoryComponentContext)

  // Refer to the custom definitions in GA or in the GoogleAnalyticsInit for the exact custom dimension name
  const customDimensions = {
    event_category: 'glance-viewed_story',
    page_location: storyUrl,
    page_title: story.title,
    dimension18: story.category,
    dimension19: story.sponsor?.uuid,
    dimension20: story.organizations?.map((org) => org.name),
  }

  // used for OutboundLinks script
  const GAPagePropertiesString = `
  window.GAPageProperties.dimension18 = '${story.category}'
  ${
    story.sponsor
      ? `window.GAPageProperties.dimension19 = '${story.sponsor.uuid}'`
      : ''
  }
  ${
    story.organizations
      ? `window.GAPageProperties.dimension20 = '${story.organizations.map(
          (org) => org.name,
        )}'`
      : ''
  }
  `

  return (
    story && (
      <Script
        id={`gtag-viewed-story-custom-dimensions_${story._id}`}
        type="text/plain"
        data-cookieconsent="statistics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              window.gtag && window.gtag('event', 'glance-track_custom_dimensions', ${JSON.stringify(
                customDimensions,
              )})
              if (window.GAPageProperties) {
                ${GAPagePropertiesString}
              }
            `,
        }}
      />
    )
  )
}
