import {baseUrl} from '../../lib/constants'
import DefaultTemplate from './template/DefaultTemplate'

export function getTemplate(templateType: 'default') {
  return {
    default: DefaultTemplate,
  }[templateType ?? 'default']
}

export function getPRSlug(slug: string) {
  return `/press-releases/${slug}/`
}

export function getPRUrl(slug: string) {
  return `${baseUrl}/press-releases/${slug}/`
}
