import {z} from 'zod'

import Link from 'next/link'

import useHasMounted from '../../../lib/hooks/useHasMounted'
import {types} from '../../../lib/sanity'
import {displayPublishedDate} from '../../../lib/utils'
import {getPRSlug} from '../utils'

const PRBlockProps = z.object({
  heading: z.string().optional(),
  pressReleases: types.PressReleasesSchema,
})

export default function PRBlock({
  heading,
  pressReleases,
}: z.infer<typeof PRBlockProps>) {
  const hasMounted = useHasMounted()

  if (!pressReleases || !pressReleases.length) return <></>

  return (
    <>
      {heading && (
        <h6 className="font-nunito text-muted text-uppercase border-bottom pb-3 mb-4">
          {heading}
        </h6>
      )}
      <div className="row gy-2 mb-4">
        {pressReleases.map(
          (pressRelease: z.infer<typeof types.PressReleaseSchema>) => (
            <div key={pressRelease._id}>
              <Link
                href={getPRSlug(pressRelease.slug.current) as string}
                legacyBehavior
              >
                <a>
                  <h6 className="mb-1">{pressRelease.title}</h6>
                </a>
              </Link>
              {pressRelease.publishedAt && hasMounted && (
                <small
                  className="p-0 d-block text-muted"
                  style={{fontSize: '0.8rem'}}
                >
                  <span>{displayPublishedDate(pressRelease.publishedAt)}</span>
                </small>
              )}
            </div>
          ),
        )}
      </div>
    </>
  )
}
