import {z} from 'zod'

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import {AuthenticationContext} from '../../lib/auth'
import {PressRelease, PressReleaseSchema} from '../../lib/sanity/types'
import {trpc} from '../../utils/trpc'
import PRAnalytics from '../appAnalytics/press-release/PRAnalytics'
import {getPRUrl, getTemplate} from './utils'

const PRComponent = (props: {
  pressRelease: PressRelease
  isVisible: boolean
  preview?: boolean
}) => {
  const {pressRelease, isVisible, preview} = props
  const Template = getTemplate(pressRelease.templateType)

  return (
    <PRComponentProvider
      pressRelease={pressRelease}
      isVisible={isVisible}
      preview={preview}
    >
      <PRAnalytics />
      <Template />
    </PRComponentProvider>
  )
}
export default PRComponent

/**
 * PRComponentProvider gives children access to the pressRelease's context.
 *  - Whether the user has viewed the pressRelease
 *  - Functions to mark the pressRelease as viewed
 *  - The pressRelease's URL
 *
 * This component fetches the pressRelease's analytics from the server and logs a page view on mount.
 *
 * Usage:
 * const {
 *   pressRelease,
 *   url,
 *   hasViewed,
 *   view
 * } = useContext(PRComponentContext)
 *
 */
export const PRComponentProvider = ({
  children,
  pressRelease,
  isVisible,
  preview,
}: {
  children: React.ReactNode
  pressRelease: PressRelease
  isVisible: boolean
  preview?: boolean
}) => {
  const {user} = useContext(AuthenticationContext)
  /**
   * Initialize state for the pressRelease
   */
  const url = getPRUrl(pressRelease.slug.current)

  const [hasViewed, setHasViewed] = useState(false)

  /**
   * On mount, get the pressRelease's user-based analytics
   */
  trpc.analytics.getAnalytics.useQuery(
    {sanityId: pressRelease._id},
    {
      refetchOnWindowFocus: false,
      enabled: !!user && false,
      onSettled: (data) => {
        if (!data) return
        setHasViewed(data.viewed)
      },
    },
  )

  /**
   * Consumers can use `view()` to log a pressRelease as viewed by the user
   */
  const {mutate: logView} = trpc.analytics.logView.useMutation()
  const view = useCallback(() => {
    if (user) {
      setHasViewed(true)
      logView({
        sanityId: pressRelease._id,
        // Send release metadata so we can use it downstream in the golden realms
        // without having to fetch it again there.
        title: pressRelease.title,
        category: pressRelease.category,
        organizations: pressRelease.organizations?.map((org) => org.name),
        tags: pressRelease.tags?.map((tag) => tag.name),
      })
    }
  }, [logView, pressRelease, setHasViewed, user])

  /**
   * Log view on mount
   */
  useEffect(() => {
    if (user) {
      view()
    }
  }, [view, user])

  /**
   * Wrap children in the context provider
   */
  return (
    <PRComponentContext.Provider
      value={{
        pressRelease,
        isVisible,
        url,
        hasViewed,
        view,
        preview,
      }}
    >
      {children}
    </PRComponentContext.Provider>
  )
}

const PRComponentContextSchema = z.object({
  pressRelease: PressReleaseSchema,
  isVisible: z.boolean(),
  preview: z.boolean().optional(),
  url: z.string(),
  hasViewed: z.boolean(),
  view: z.function(),
})
export const PRComponentContext = createContext<
  z.infer<typeof PRComponentContextSchema>
>({
  pressRelease: {} as PressRelease,
  preview: false,
  isVisible: false,
  url: '',
  hasViewed: false,
  view: () => void 0,
})
