import React, {useEffect} from 'react'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: fancyapp should have ts in future v5 update
import {Fancybox as NativeFancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox.css'

interface FancyboxWrapperProps {
  options: {infinite: boolean}
  children: JSX.Element
}

const FancyboxWrapper = ({options, children}: FancyboxWrapperProps) => {
  const delegate = '[data-fancybox]'

  useEffect(() => {
    const opts = options || {}

    NativeFancybox.bind(delegate, opts)

    return () => {
      NativeFancybox.destroy()
    }
  }, [options])

  return <>{children}</>
}

export default FancyboxWrapper
