import {useEffect, useMemo} from 'react'

import {useGPT} from '../../lib/ads/GPTContext'
import {PressRelease, Story} from '../../lib/sanity/types'
import {createAd, getAdSpecs} from '../../lib/ads/utils'

export default function BannerAd({
  adCode,
  document,
}: {
  adCode: string
  document?: Story | PressRelease
  onAdRenderedCallback?: () => void
}) {
  const {GPTHasLoaded} = useGPT()
  const adSpecs = useMemo(
    () => getAdSpecs(adCode, 'banner', document?._id),
    [adCode, document?._id],
  )
  const {id} = adSpecs

  // Create ad
  useEffect(() => {
    return createAd(adSpecs, GPTHasLoaded, document)
  }, [GPTHasLoaded, adSpecs, document])

  return (
    <div
      id={id}
      className={`d-flex justify-content-center align-items-center`}
    />
  )
}
