import Link from 'next/link'

import {PortableText, PortableTextBlock} from './sanity/types'

/**
 * Stories pass a date slug from the URL which needs a time appended to get the correct date.
 * Press releases pass a date string from the API which is already in the correct format,
 * so we don't need to append a time.
 */
export function displayPublishedDate(dateSlug: string) {
  if (!dateSlug.includes('T')) {
    dateSlug = `${dateSlug}T00:00`
  }
  const month = new Date(dateSlug).toLocaleString('en-US', {
    month: 'short',
  })
  const day = dateSlug.slice(8, 10)
  const year = dateSlug.slice(0, 4)
  return `${month} ${day}, ${year}`
}

export function linkify(
  jsx: JSX.Element,
  link: string,
  config = {newTab: false},
) {
  if (!link) return jsx
  return (
    <Link href={link} legacyBehavior>
      <a target={config.newTab ? '_blank' : '_self'}>{jsx}</a>
    </Link>
  )
}

export function titleCase(str: string) {
  if (!str) return str
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeFalsy(obj: {[x: string]: any}) {
  const newObj = {}
  Object.keys(obj).forEach((prop) => {
    if (obj[prop]) {
      newObj[prop] = obj[prop]
    }
  })
  return newObj
}

export function displayAuthors(authors: Array<string>) {
  if (!authors) {
    return ''
  }
  if (authors.length > 3) {
    return [...authors.slice(0, 3), 'et al.'].join(', ')
  }
  return authors.join(', ')
}

export function isInViewport(element: Element) {
  if (!element) return false
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

/**
 * Returns whether the entire element is visible in the viewport.
 * @param element
 * @returns {boolean}
 */
export function isElementVisible(element: Element) {
  if (!element) return false
  const elementRect = element.getBoundingClientRect()
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight

  return elementRect.top <= windowHeight
}

/**
 * Returns whether any part of the element is visible in the viewport
 * @param element
 * @returns {boolean}
 */
export function isPartiallyInViewport(element: Element) {
  if (!element) return false
  const rect = element.getBoundingClientRect()
  const topIsBelowViewport = rect.top > window.innerHeight
  const bottomIsAboveViewport = rect.bottom < 0
  return !topIsBelowViewport && !bottomIsAboveViewport
}

// Formats the display of hit counts, e.g. 8427 becomes 8.4k
export function displayHitCount(hits: number) {
  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(hits)
}

// From https://www.30secondsofcode.org/js/s/slugify
export const slugify = (str: string) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')

// From https://www.sanity.io/docs/presenting-block-text#ac67a867dd69
export function portableTextToString(blocks: PortableText) {
  return (
    blocks
      // loop through each block
      .map((block: PortableTextBlock) => {
        // if it's not a text block with children,
        // return nothing
        if (block._type !== 'block' || !block.children) {
          return ''
        }
        // loop through the children spans, and join the
        // text strings
        return block.children.map((child) => child.text).join('')
      })
      // join the paragraphs leaving split by two linebreaks
      .join('\n\n')
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeDuplicateObjects(arr: any[]) {
  // create an empty object to store unique values
  const seen: {[key: string]: boolean} = Object.create(null)

  // iterate over the input array
  return arr.filter(function (item) {
    // check if the current item has already been seen
    const key = JSON.stringify(item)
    if (key in seen) {
      // if the item has already been seen, return false to remove it from the array
      return false
    } else {
      // otherwise, add the item to the object and return true to keep it in the array
      seen[key] = true
      return true
    }
  })
}
