import {useContext} from 'react'

import {PortableText} from '@portabletext/react'
import {FeaturedTextComponents} from '../../../lib/sanity/portableTextComponents'
import {StoryComponentContext} from '../StoryComponent'

export default function StickyFooterContent() {
  const {story} = useContext(StoryComponentContext)

  if (!story.stickyFooter) {
    return <></>
  }
  return (
    <div className="mb-4" id={`stickyFooterContent-${story._id}`}>
      <div className="card" style={{border: '3px solid #007bff'}}>
        <div className="card-body">
          <div className="mt-3 break-word">
            <PortableText
              value={story.stickyFooter.stickyFooterContent}
              components={{
                marks: {
                  ...FeaturedTextComponents.marks,
                  textColor: (props) => (
                    <span style={{color: props.value.hex}}>
                      {props.children}
                    </span>
                  ),
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
