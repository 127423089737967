import {z} from 'zod'

import {useContext} from 'react'

import Link from 'next/link'

import {AuthenticationContext} from '../../lib/auth'
import {getStudioUrl} from '../../lib/sanity'

const props = z.object({
  sanityType: z.string(),
  sanityId: z.string(),
  primary: z.boolean().optional(),
})

const EditInSanityButton = ({
  sanityType,
  sanityId,
  primary = true,
}: z.infer<typeof props>) => {
  const {user, isAuthenticating} = useContext(AuthenticationContext)
  const url = `${getStudioUrl()}/desk/${sanityType};${sanityId}`

  if (isAuthenticating || !user?.is_staff) return <></>
  return (
    <Link
      href={url}
      target="_blank"
      className={`btn ${
        primary ? 'btn-primary' : 'btn-outline-secondary'
      } text-white font-aesthet text-xl py-1 mb-3`}
    >
      Edit
    </Link>
  )
}
export default EditInSanityButton
