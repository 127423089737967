import groq from 'groq'
import {z} from 'zod'

import {useEffect, useState} from 'react'

import type {PortableTextBlock} from '@portabletext/types'
import {createClient} from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'

import {trpc} from '../../utils/trpc'
import * as queries from './queries'
import * as requests from './requests'
import * as types from './types'
import {PressRelease, SanityImageAsset, Story} from './types'

/**
 * Export Sanity types and queries from this index file to simplify imports downstream.
 */
export {types, queries, requests}

/**
 * Sanity client that fetches fresh data from Sanity. This is used for
 * serverside fetching and requires a token that is unavailable to the client.
 */
export const sanity = createClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  apiVersion: '2021-03-25',
  token: process.env.SANITY_API_TOKEN,
  useCdn: false, // false to ensure fresh data
})

/**
 * Sanity client that fetches cached data from Sanity. This is used for
 * clientside fetching and does not require a token.
 */
export const cdnSanity = createClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  apiVersion: '2021-08-31',
  useCdn: true, // true to ensure fast data
})

export const getStudioUrl = () => {
  const env = process.env.NODE_ENV
  if (env === 'development') {
    return 'http://localhost:3333'
  }
  return 'https://eoe1.sanity.studio'
}

export const sanityAPI = 'https://api.sanity.io/v1'

const imageBuilder = imageUrlBuilder(sanity)
/**
 * Given a Sanity Image, return a URL Builder to use as the `src` attribute in
 * an image tag
 */
export const getImageSource = (
  source: z.infer<typeof SanityImageAsset> | undefined | null,
  backup = null,
) => {
  const backupImage =
    'https://cdn.sanity.io/images/51cpf7jm/production/b9a649fbcce5a3bb16a8c2fd638ef1bbf8d6104a-1200x628.png'
  return imageBuilder.image(source ?? backup ?? backupImage)
}

export const getFormattedImage = (
  sanityImg: z.infer<typeof SanityImageAsset> | undefined | null,
  width: number,
) => {
  try {
    return sanityImg
      ? getImageSource(sanityImg)
          .width(width)
          .quality(100)
          .fit('fill')
          .auto('format')
          .url()
      : '/logos/eoe-logo-card-default.png'
  } catch {
    return '/logos/eoe-logo-card-default.png'
  }
}

/**
 * This hook fetches infinite scroll stories and keeps track of state.
 * - Pass in an organizationId to target stories from a specific organization.
 * - Pass in a category to target stories from a specific category.
 * - Pass in ignoredIds if you want to prevent certain results from being returned
 *    (like the user's currently viewed story)
 * @param opts
 */
export const useInfiniteScroll = (opts: {
  primaryStory: Story
  ignoredIds: string[]
  category: string | null
  organizationId: string | null
}) => {
  const {primaryStory, ignoredIds} = opts
  const [stories, setStories] = useState<Array<Story>>([primaryStory])
  const [hiddenStories, setHiddenStories] = useState<Array<Story>>([])

  /**
   * When the story changes, reset the state of infinite scroll
   */
  useEffect(() => {
    setStories([primaryStory])
    setHiddenStories([])
  }, [primaryStory])

  /**
   * Use loadAnotherStory() to load another story into the infinite scroll
   * <button onClick={() => loadAnotherStory()}>Load More</button>
   */
  const loadAnotherStory = () => {
    if (hiddenStories.length > 0) {
      const newStory = types.StorySchema.parse(hiddenStories[0])
      setStories((prev) => [...prev, newStory])
      setHiddenStories((prev) => prev.slice(1))
    }
  }

  /**
   * tRPC hook to fetch infinite scroll stories
   */
  const tryPullInfiniteScroll = trpc.sanity.getInfiniteScrollStories.useQuery(
    {
      ignoredStories: ignoredIds,
      category: opts.category ?? undefined,
      organizationId: opts.organizationId ?? undefined,
    },
    {
      onSettled: (newStories) => {
        setHiddenStories(types.StoriesSchema.parse(newStories))
      },
      refetchOnWindowFocus: false,
    },
  )

  return {
    infiniteScrollStatus: tryPullInfiniteScroll,
    stories,
    loadAnotherStory,
  }
}

/**
 * Given a primary story, this hook fetches related category stories and keeps track of state.
 * @param opts
 */
export const useCategoryStories = (opts: {primaryStory: Story}) => {
  const {primaryStory} = opts
  const [stories, setStories] = useState<Array<Story>>([])

  /**
   * tRPC hook to fetch stories with the same category as the primary story
   */
  const tryPullStories = trpc.sanity.getCategoryStories.useQuery(
    {
      category: primaryStory.category,
      ignoredStories: [primaryStory._id],
    },
    {
      enabled: !!primaryStory.category,
      onSettled: (newStories) => {
        const newStoriesValid = types.StoriesSchema.safeParse(newStories)
        if (newStoriesValid.success) {
          setStories(newStories as Array<Story>)
        }
      },
      refetchOnWindowFocus: false,
    },
  )

  return {
    status: tryPullStories,
    stories,
  }
}

/**
 * Given a primary press release, this hook fetches related category stories and keeps track of state.
 * @param opts
 */
export const useCategoryPressReleases = (opts: {
  primaryPressRelease: PressRelease
}) => {
  const {primaryPressRelease} = opts
  const [pressReleases, setPressReleases] = useState<Array<PressRelease>>([])

  /**
   * tRPC hook to fetch stories with the same category as the primary press release
   */
  const tryPullPressReleases = trpc.sanity.getCategoryPressReleases.useQuery(
    {
      category: primaryPressRelease.category,
      ignoredPressReleases: [primaryPressRelease._id],
    },
    {
      enabled: !!primaryPressRelease.category,
      onSettled: (newPressReleases) => {
        const newPressReleasesValid =
          types.PressReleasesSchema.safeParse(newPressReleases)
        if (newPressReleasesValid.success) {
          setPressReleases(newPressReleases as Array<PressRelease>)
        }
      },
      refetchOnWindowFocus: false,
    },
  )

  return {
    status: tryPullPressReleases,
    pressReleases,
  }
}

export const OrganizationStories = z.array(
  z.object({
    organization: types.OrganizationSchema,
    stories: types.StoriesSchema,
  }),
)
/**
 * Given a primary story, this hook fetches related organization stories and keeps track of state.
 * @param opts
 */
export const useOrganizationStories = (opts: {primaryStory: Story}) => {
  const {primaryStory} = opts
  const [stories, setStories] = useState<z.infer<typeof OrganizationStories>>(
    [],
  )

  /**
   * tRPC hook to fetch stories with the same organizations as the primary story
   */
  const tryPullOrganizationStories =
    trpc.sanity.getOrganizationStories.useQuery(
      {
        organizations: primaryStory.organizations || [],
        ignoredStories: [primaryStory._id],
      },
      {
        enabled: !!primaryStory.organizations,
        onSettled: (newStories) => {
          setStories(newStories || [])
        },
        refetchOnWindowFocus: false,
      },
    )

  return {
    status: tryPullOrganizationStories,
    stories,
  }
}

export const OrganizationPressReleases = z.array(
  z.object({
    organization: types.OrganizationSchema,
    pressReleases: types.PressReleasesSchema,
  }),
)
/**
 * Given a primary story, this hook fetches related organization press releases and keeps track of state.
 * @param opts
 */
export const useOrganizationPressReleases = (opts: {
  primaryPressRelease: PressRelease
}) => {
  const {primaryPressRelease} = opts
  const [pressReleases, setPressReleases] = useState<
    z.infer<typeof OrganizationPressReleases>
  >([])

  /**
   * tRPC hook to fetch press releases with the same organizations as the primary press release
   */
  const tryPullOrganizationPressReleases =
    trpc.sanity.getOrganizationPressReleases.useQuery(
      {
        organizations: primaryPressRelease.organizations || [],
        ignoredPressReleases: [primaryPressRelease._id],
      },
      {
        enabled: !!primaryPressRelease.organizations,
        onSettled: (newPressReleases) => {
          setPressReleases(newPressReleases || [])
        },
        refetchOnWindowFocus: false,
      },
    )

  return {
    status: tryPullOrganizationPressReleases,
    pressReleases,
  }
}

/**
 * Joins all the text in Portable Text children to create one string
 */
export const portableTextToString = (blocks: PortableTextBlock[] = []) => {
  return (
    blocks
      // loop through each block
      .map((block: PortableTextBlock) => {
        // if it's not a text block with children,
        // return nothing
        if (block._type !== 'block' || !block.children) {
          return ''
        }
        // loop through the children spans, and join the
        // text strings
        return block.children.map((child) => child.text).join('')
      })
      // join the paragraphs leaving split by two linebreaks
      .join('\n\n')
  )
}

// Close the loop between Sanity projections and Typescript/Zod schemas
export type ProjectionValidator = {
  projection: string
  validator: z.ZodSchema
}

// Define all the contexts that we want to fetch author documents
export type AuthorContexts = 'profilePicture'

// Map contexts to their projection and associated validator
export const AuthorProjectionValidators: Record<
  AuthorContexts,
  ProjectionValidator
> = {
  profilePicture: {
    projection: groq`.profilePicture.asset->`,
    validator: SanityImageAsset,
  },
}

// Shortcut type for all the validated author contexts
export type AuthorSchemas =
  (typeof AuthorProjectionValidators)[AuthorContexts]['validator']
