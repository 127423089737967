import {useCallback, useEffect, useRef} from 'react'

import {create} from '@lottiefiles/lottie-interactivity'

interface LottieFileProps {
  _key: string
  loop: boolean
  height: number
  lottieUrl: string
}

export default function LottieFile({
  _key,
  loop,
  height,
  lottieUrl,
}: LottieFileProps) {
  const lottieRef = useRef<HTMLElement>(null)

  const createLottie = useCallback(() => {
    create({
      player: `#lottie-${_key}`,
      mode: 'scroll',
      actions: [
        {
          visibility: [0.3, 1.0],
          type: loop ? 'loop' : 'playOnce',
          frames: [0],
        },
      ],
    })
  }, [_key, loop])

  useEffect(() => {
    import('@lottiefiles/lottie-player')

    let lottieRefValue: HTMLElement | null = null
    if (lottieRef.current) {
      lottieRef.current.addEventListener('load', createLottie)
      lottieRefValue = lottieRef.current
    }

    return () => {
      if (lottieRefValue)
        lottieRefValue.removeEventListener('load', createLottie)
    }
  }, [createLottie, lottieRef])

  return (
    <div className="d-flex justify-content-center my-3">
      <lottie-player
        ref={lottieRef}
        mode="normal"
        background="transparent"
        speed="1"
        className="img-fluid"
        id={`lottie-${_key}`}
        src={lottieUrl}
        style={{
          width: 'auto',
          height: height ? `${height}px` : 'auto',
        }}
      />
    </div>
  )
}
