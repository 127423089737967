import {ANALYTICS_ENABLED} from '../../../lib/analytics/appAnalytics'
import GAViewedStory from './GAViewedStory'
// import TwitterPageView from '../TwitterPageView'
// import AdRollPixels from './AdRollPixels'
// import FacebookViewedResource from './FacebookViewedResource'
import MixpanelViewedStory from './MixpanelViewedStory'

export default function StoryAnalytics() {
  if (!ANALYTICS_ENABLED) return null

  return (
    <>
      <GAViewedStory />
      <MixpanelViewedStory />
      {/* <FacebookViewedResource /> */}
      {/* <TwitterPageView /> */}
      {/* <AdRollPixels /> */}
    </>
  )
}
