import {useContext} from 'react'

import {PortableText} from '@portabletext/react'

import PortableTextComponents from '../../../lib/sanity/portableTextComponents'
import {StoryComponentContext} from '../StoryComponent'
import StickyFooterContent from './StickyFooterContent'

export default function Body() {
  const {story} = useContext(StoryComponentContext)

  return (
    <div className="story-body break-word">
      {story?.body && (
        <div className="my-3 text-body">
          <article id={`story-body__${story._id}`}>
            <PortableText
              value={story.body}
              components={PortableTextComponents}
            />
          </article>
        </div>
      )}
      <StickyFooterContent />
    </div>
  )
}
