import {z} from 'zod'

import {ReactNode, useRef, useState} from 'react'
import {Overlay} from 'react-bootstrap'

import {PortableText} from '@portabletext/react'

import {PortableTextSchema, SanityImage} from '../../../lib/sanity/types'
import styles from '../../../styles/modules/CalloutBlock.module.scss'
import {trpc} from '../../../utils/trpc'
import SanityImageComponent from '../../images/SanityImageComponent'

type Callout = {
  buttonLink: string
  buttonText: string
  description: z.infer<typeof PortableTextSchema>
  logo: z.infer<typeof SanityImage>
  title: string
}

type CalloutBlockProps = {
  portableTextChildren: ReactNode
  referenceId: string
}
/**
 * Display inner html display a callout card on hover.
 */
export const CalloutBlock = (props: CalloutBlockProps) => {
  const {referenceId, portableTextChildren} = props
  const triggerRef = useRef(null)
  const [callout, setCallout] = useState<Callout | null>(null)
  const [show, setShow] = useState(false)

  trpc.sanity.getReferenceData.useQuery(
    {
      documentType: 'callout',
      referenceId,
    },
    {
      enabled: referenceId !== undefined,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setCallout(data)
      },
    },
  )

  return (
    <>
      {/* This span displays the overlay on click and hover */}
      <span
        ref={triggerRef}
        className="position-relative"
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        onClick={() => setShow(!show)}
      >
        <strong className="text-primary" style={{cursor: 'pointer'}}>
          {portableTextChildren}
        </strong>
      </span>

      {/* Display the callout card */}
      {callout && (
        <Overlay
          target={triggerRef.current}
          show={show}
          placement="bottom-start"
        >
          {/* react-bootstrap is effed, you have to destructure unused props here so they don't get spread later and break the DOM */}
          {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
          {({placement, arrowProps, show: _show, popper, ...props}) => (
            <div
              className={`card position-absolute ${styles.callout}`}
              {...props}
              onMouseLeave={() => setShow(false)}
              onMouseEnter={() => setShow(true)}
            >
              <div className="card-body">
                <div className="d-flex align-items-center">
                  {callout.logo && (
                    <SanityImageComponent
                      src={callout.logo.asset}
                      alt={callout.title}
                      width={50}
                      height={50}
                      className="rounded-circle border border-primary"
                    />
                  )}
                  <span className="h4 ms-2 mb-0">{callout.title}</span>
                </div>
                <div className="mt-2">
                  <PortableText value={callout.description} />
                  <div className="d-flex justify-content-center">
                    <a
                      href={callout.buttonLink}
                      className="btn btn-outline-primary"
                    >
                      {callout.buttonText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Overlay>
      )}
    </>
  )
}
