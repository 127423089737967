import {z} from 'zod'

export const BlockContentSchema = z
  .object({
    _type: z.string(),
    _key: z.string(),
    children: z.array(z.any()).optional().nullable(),
  })
  .passthrough()
export type PortableTextBlock = z.infer<typeof BlockContentSchema>
export const PortableTextSchema = z.array(BlockContentSchema)
export type PortableText = z.infer<typeof PortableTextSchema>

export const SanityImagePaletteSwatchSchema = z.object({
  _type: z.literal('sanity.imagePaletteSwatch'),
  background: z.string(),
  foreground: z.string(),
  population: z.number(),
  title: z.string(),
})

export const SanityImagePalette = z.object({
  _type: z.literal('sanity.imagePalette'),
  darkMuted: SanityImagePaletteSwatchSchema,
  darkVibrant: SanityImagePaletteSwatchSchema,
  dominant: SanityImagePaletteSwatchSchema,
  lightMuted: SanityImagePaletteSwatchSchema,
  lightVibrant: SanityImagePaletteSwatchSchema,
  muted: SanityImagePaletteSwatchSchema,
  vibrant: SanityImagePaletteSwatchSchema,
})

export const SanityImageMetadata = z.object({
  _type: z.literal('sanity.imageMetadata').optional(),
  blurhash: z.string().optional(),
  hasAlpha: z.boolean().optional(),
  isOpaque: z.boolean().optional(),
  lqip: z.string().optional(),
  dimensions: z.object({
    _type: z.literal('sanity.imageDimensions'),
    aspectRatio: z.number(),
    height: z.number(),
    width: z.number(),
  }),
  palette: SanityImagePalette.optional(),
})

export const SanityImageAsset = z.object({
  _createdAt: z.string().optional(),
  _id: z.string().optional(),
  _rev: z.string().optional(),
  _updatedAt: z.string().optional(),
  assetId: z.string().optional(),
  extension: z.string().optional(),
  mimeType: z.string().optional(),
  originalFilename: z.string().optional(),
  path: z.string().optional(),
  sha1hash: z.string().optional(),
  size: z.number().optional(),
  uploadId: z.string().optional(),
  url: z.string().url().optional(),
  metadata: SanityImageMetadata.optional(),
})
export const SanityImage = z.object({
  _type: z.literal('image'),
  asset: SanityImageAsset.nullable(),
})

export const OrganizationSchema = z.object({
  _id: z.string(),
  _type: z.literal('organization').optional(),
  _rev: z.string().optional(),
  _createdAt: z.string().optional(),
  _updatedAt: z.string().optional(),
  name: z.string(),
  slug: z.object({
    current: z.string(),
  }),
  uuid: z.string().optional().nullable(),
  image: SanityImage.optional().nullable(),
  description: PortableTextSchema.optional().nullable(),
  website: z.string().url().nullish(),
  socialLinks: z.array(z.string()).optional(),
})
export type Organization = z.infer<typeof OrganizationSchema>
export const OrganizationsSchema = z.array(OrganizationSchema)
export type Organizations = z.infer<typeof OrganizationsSchema>

export const TagSchema = z.object({
  _id: z.string(),
  _type: z.literal('tag'),
  _rev: z.string(),
  _createdAt: z.string(),
  _updatedAt: z.string(),
  name: z.string(),
})
export type Tag = z.infer<typeof TagSchema>

export const AuthorSchema = z.object({
  _id: z.string(),
  _type: z.literal('author'),
  _rev: z.string().optional(),
  _createdAt: z.string().optional(),
  _updatedAt: z.string().optional(),
  name: z.string(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string().optional(),
  slug: z.object({
    current: z.string().nullable(),
  }),
  profilePicture: SanityImage.optional().nullable(),
  bio: PortableTextSchema.optional().nullable(),
  socialLinks: z.array(z.string()).optional().nullable(),
  editorialBoardMembership: z.string().optional().nullable(),
})
export type Author = z.infer<typeof AuthorSchema>

export const StorySchema = z.object({
  _id: z.string(),
  _type: z.literal('story'),
  _rev: z.string().optional(),
  _createdAt: z.string().optional(),
  publishedAt: z.string(), // mm/dd/YYYY ISO string
  dateSlug: z.string(),
  _updatedAt: z.string().optional(),
  title: z.string(),
  titleOverride: PortableTextSchema.optional().nullable(),
  slug: z.object({
    current: z.string(),
  }),
  featuredImage: SanityImage.optional().nullable(),
  featuredText: PortableTextSchema.optional().nullable(),
  featured: z.boolean().optional().nullable(),
  estimatedReadTime: z.number().optional().nullable(),
  sponsor: OrganizationSchema.optional().nullable(),
  organizations: OrganizationsSchema.optional().nullable(),
  body: PortableTextSchema,
  disclosure: z.enum([
    'default',
    'advertising',
    'sponsor',
    'sponsorAdvertising',
  ]),
  citations: PortableTextSchema.optional().nullable(),
  templateType: z.enum(['default']),
  showAds: z.boolean().optional().nullable(),
  showInReadAds: z.boolean().optional().nullable(),
  inReadAdIteration: z.number().optional().nullable(),
  category: z.enum([
    'Business',
    'Research',
    'Pipeline',
    'Products',
    'Legal',
    'Archives',
    'Events',
  ]),
  tags: z.array(TagSchema).optional().nullable(),
  authors: z.array(AuthorSchema).optional().nullable(),
  containsLottie: z.boolean().optional().nullable(),
  stickyFooter: z
    .object({
      stickyFooterCTA: PortableTextSchema,
      stickyFooterContent: PortableTextSchema,
    })
    .nullish(),
})
export type Story = z.infer<typeof StorySchema>
export const StoriesSchema = z.array(StorySchema)
export type Stories = z.infer<typeof StoriesSchema>

export const PressReleaseSchema = z.object({
  _id: z.string(),
  _type: z.literal('pressRelease'),
  _rev: z.string(),
  _createdAt: z.string(),
  _updatedAt: z.string(),
  title: z.string(),
  body: PortableTextSchema,
  organizations: OrganizationsSchema.optional().nullable(),
  tags: z.array(TagSchema).optional().nullable(),
  category: z.enum([
    'Business',
    'Research',
    'Pipeline',
    'Products',
    'Legal',
    'Archives',
    'Events',
  ]),
  slug: z.object({
    current: z.string(),
  }),
  templateType: z.enum(['default']),
  publishedAt: z.string(), // mm/dd/YYYY ISO string
  authors: z.array(AuthorSchema).optional().nullable(),
  showAds: z.boolean().optional().nullable(),
})

export const PressReleasesSchema = z.array(PressReleaseSchema)
export type PressRelease = z.infer<typeof PressReleaseSchema>
export type PressReleases = z.infer<typeof PressReleasesSchema>

const GlancePageSchema = z.discriminatedUnion('_type', [
  StorySchema,
  PressReleaseSchema,
])
export type GlancePage = z.infer<typeof GlancePageSchema>
