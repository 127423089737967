import {useContext, useEffect, useState} from 'react'

import {faChevronCircleDown} from '@fortawesome/pro-duotone-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {PortableText} from '@portabletext/react'
import {FeaturedTextComponents} from '../../../lib/sanity/portableTextComponents'

import styles from '../../../styles/modules/StoryStickyFooter.module.scss'
import {isElementVisible} from '../../../lib/utils'
import {StoryComponentContext} from '../StoryComponent'

export default function StoryStickyFooter() {
  const {story} = useContext(StoryComponentContext)
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const element = document.getElementById(`stickyFooterContent-${story._id}`)
    const handleScroll = () => {
      if (element) {
        setVisible(!isElementVisible(element))
      }
    }
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [story._id])

  if (!visible || !story.stickyFooter) {
    return <></>
  }

  return (
    <div
      className={`${styles.stickyFooter} fixed-bottom justify-content-center`}
    >
      <div className="container">
        <div className="d-flex justify-content-between align-items-center py-3">
          <div className={`${styles.stickyFooterCTA} me-5`}>
            <PortableText
              value={story.stickyFooter.stickyFooterCTA}
              components={{
                marks: {
                  ...FeaturedTextComponents.marks,
                  textColor: (props) => (
                    <span style={{color: props.value.hex}}>
                      {props.children}
                    </span>
                  ),
                },
              }}
            />
          </div>
          <a href={`#stickyFooterContent-${story._id}`}>
            <FontAwesomeIcon
              role="button"
              className="text-primary"
              size="2x"
              icon={faChevronCircleDown}
            />
          </a>
        </div>
      </div>
    </div>
  )
}
