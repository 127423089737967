import {useContext} from 'react'

import {faSparkles} from '@fortawesome/pro-duotone-svg-icons'
import {faCalendar, faStopwatch} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {PortableText} from '@portabletext/react'

import useHasMounted from '../../../lib/hooks/useHasMounted'
import {FeaturedTextComponents} from '../../../lib/sanity/portableTextComponents'
import {displayPublishedDate} from '../../../lib/utils'
import {BylineComponent} from '../../authors/BylineComponent'
import {StoryComponentContext} from '../StoryComponent'
import {getDisclosure} from '../utils'
import BannerAd from '../../ads/BannerAd'

export default function StoryHeader() {
  const {story} = useContext(StoryComponentContext)
  const disclosure = getDisclosure(story?.disclosure, story?.sponsor?.name)
  const hasMounted = useHasMounted()

  if (!story) return <></>

  return (
    <div className="mb-4">
      <div className="mb-3">
        <BannerAd adCode="glance_story_header" document={story} />
      </div>
      {story.category && (
        <div className="text-uppercase col d-flex align-items-center mt-2 mt-md-0">
          <span style={{fontSize: '.8rem'}}>
            Published in{' '}
            <a
              className="text-primary"
              href={`/stories/?category=${story.category}`}
            >
              {story.category}
            </a>
          </span>
        </div>
      )}
      {story.titleOverride ? (
        <h1 className="mb-n2">
          <PortableText
            value={story.titleOverride}
            components={FeaturedTextComponents}
          />
        </h1>
      ) : (
        <h1>{story.title}</h1>
      )}
      <div
        className="d-flex align-items-center flex-wrap mb-1"
        style={{gap: '0.25rem'}}
      ></div>
      {/* Author byline https://app.clickup.com/t/866avkr3v */}
      <div
        className="d-flex align-items-center flex-wrap mb-2"
        style={{gap: '0.25rem'}}
      >
        {story.authors?.map((author) => {
          return <BylineComponent author={author} key={author._id} />
        })}
      </div>
      {disclosure && (
        <div className="mb-3">
          <span className="badge bg-light text-wrap text-left text-black fw-semibold">
            <FontAwesomeIcon icon={faSparkles} className="text-warning me-1" />
            {disclosure}
          </span>
        </div>
      )}
      <div
        className="d-flex flex-wrap p-1 mb-3 border-top border-bottom text-gray-800 blocked"
        style={{fontSize: '.8rem'}}
      >
        {hasMounted && (
          <div className="d-flex align-items-center me-4">
            <FontAwesomeIcon icon={faCalendar} className="me-2 text-gray-500" />
            <span>{displayPublishedDate(story.dateSlug)}</span>
          </div>
        )}
        {story.estimatedReadTime && (
          <div className="d-flex align-items-center me-4">
            <span>
              <FontAwesomeIcon
                icon={faStopwatch}
                className="me-2 text-gray-500"
              />
              {story.estimatedReadTime} min read
            </span>
          </div>
        )}
      </div>
    </div>
  )
}
