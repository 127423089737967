// these functions are assumed to be used if GPTHasLoaded is true
// https://developers.google.com/publisher-tag/guides/ad-sizes#responsive_ads
import {PressRelease, Story} from '../sanity/types'

export interface Ad {
  path: string
  adSizes: googletag.GeneralSize
  id?: string
  sizeMap?: googletag.SizeMappingArray | null
  adType?: 'banner' | 'stickyFooter'
}

export const getAdSpecs = (
  adCode: string,
  adType: 'banner' | 'stickyFooter',
  id?: string,
): Ad => ({
  // Currently the same adSizes are used for both banner and stickyFooter.
  // Add a getAdSizes function if there are any other ad sizes needed in the future.
  adSizes: [
    [320, 50],
    [728, 90],
    [300, 50],
  ],
  adType,
  id: `glance-${adCode}${id ? `_${id}` : ''}`,
  path: `/21611127761/${adCode}`,
})

export const getSizeMap = (adType?: 'banner' | 'stickyFooter') => {
  switch (adType) {
    case 'banner':
      return googletag
        .sizeMapping()
        .addSize([768, 0], [728, 90])
        .addSize(
          [0, 0],
          [
            [320, 50],
            [300, 50],
          ],
        )
        .build()
    case 'stickyFooter':
      return googletag
        .sizeMapping()
        .addSize([576, 0], [728, 90])
        .addSize(
          [0, 0],
          [
            [320, 50],
            [300, 50],
          ],
        )
        .build()
  }
}

export const defineSlot = ({path, adSizes, id, adType}: Ad) => {
  const slot = googletag.defineSlot(path, adSizes, id)

  if (slot) {
    const sizeMap = getSizeMap(adType)
    if (sizeMap) {
      slot.defineSizeMapping(sizeMap)
    }
    slot.setCollapseEmptyDiv(true)
    return slot.addService(googletag.pubads())
  }
}

/**
 * This is the function used in an ad component's useEffect to handle all aspects of an ad:
 * 1. Defining the ad slot and enabling google tag services
 * 2. Displaying the ad and adding event listeners for when the slot has finished rendering the ad
 * 3. Returning the cleanup function for the useEffect in order to destroy all slots and remove side effects
 * @see https://developers.google.com/publisher-tag/guides/get-started#custom-ad
 * @param adSpecs
 * @param GPTHasLoaded
 * @param document
 * @param refresh
 * @param onSuccessfulRender
 * @param onFailedRender
 * @returns useEffect cleanup function
 */
export const createAd = (
  adSpecs: Ad,
  GPTHasLoaded: boolean,
  document?: Story | PressRelease,
  refresh = true,
  onSuccessfulRender?: () => void,
  onFailedRender?: () => void,
) => {
  let adSlot: googletag.Slot | undefined
  let refreshAdCleanup: () => void

  function onSlotRenderEnded(event: googletag.events.SlotRenderEndedEvent) {
    if (event.slot === adSlot) {
      if (event.isEmpty) {
        onFailedRender?.()
      } else {
        onSuccessfulRender?.()
      }
    }
  }

  if (adSpecs && GPTHasLoaded) {
    // create ad
    try {
      googletag.cmd.push(() => {
        adSlot = defineSlot(adSpecs)

        if (adSlot) {
          if (document) setAdTargeting(adSlot, document)
          if (refresh) {
            refreshAdCleanup = refreshAfterViewable(adSlot)
          }
        }

        googletag.pubads().enableLazyLoad({
          fetchMarginPercent: 100,
          renderMarginPercent: 50,
          mobileScaling: 3.0,
        })

        googletag.enableServices()
      })
    } catch (e) {
      // continue regardless of error
    }

    // display ad
    try {
      googletag.cmd.push(() => {
        adSpecs.id && googletag.display(adSpecs.id)
        // https://developers.google.com/publisher-tag/samples/ad-event-listeners
        googletag
          .pubads()
          .addEventListener('slotRenderEnded', onSlotRenderEnded)
      })
    } catch (e) {
      // continue regardless of error
    }
  }

  return function cleanup() {
    if (adSlot) {
      try {
        googletag.cmd.push(function () {
          if (refreshAdCleanup) {
            refreshAdCleanup()
          }
          googletag
            .pubads()
            .removeEventListener('slotRenderEnded', onSlotRenderEnded)
          if (googletag.destroySlots && adSlot) {
            googletag.destroySlots([adSlot])
          }
        })
      } catch (e) {
        // continue regardless of error
      }
    }
  }
}

export function displayGPTAd(adID: string | Element | googletag.Slot) {
  // https://developers.google.com/publisher-tag/reference#window.googletag.display
  // Instructs slot services to render the slot. Each ad slot should only be displayed once per page.
  // All slots must be defined and have a service associated with them before being displayed.
  // The display call must not happen until the element is present in the DOM.
  try {
    googletag.cmd.push(() => {
      googletag.display(adID)
    })
  } catch (e) {
    // continue regardless of error
  }
}

/**
 * Only refresh viewable ad slots to avoid lowering the "ActiveView" rate
 * @see https://developers.google.com/publisher-tag/guides/control-ad-loading#best_practices
 */
export function refreshAfterViewable(targetSlot: googletag.Slot, mins = 2) {
  let isFirstOccurrence = true
  let intervalId: number | undefined

  function refreshAd(event: googletag.events.ImpressionViewableEvent) {
    const slot = event.slot

    if (slot === targetSlot && isFirstOccurrence) {
      intervalId = window.setInterval(
        () => {
          if (googletag.cmd) {
            googletag.cmd.push(() => {
              googletag.pubads().refresh([targetSlot])
            })
          }
        },
        1000 * 60 * mins,
      )
      isFirstOccurrence = false
    }
  }
  googletag.pubads().addEventListener('impressionViewable', refreshAd)

  return function cleanup() {
    googletag.pubads().removeEventListener('impressionViewable', refreshAd)
    if (intervalId !== undefined) {
      clearInterval(intervalId)
    }
  }
}

export function insertInReadAd(element: HTMLElement | undefined, story: Story) {
  googletag.cmd.push(() => {
    const slot = defineSlot({
      path: '/21611127761/glance_story_inread',
      adSizes: [
        [728, 90],
        [300, 250],
      ],
      sizeMap: googletag
        .sizeMapping()
        .addSize([992, 0], [728, 90])
        .addSize([0, 0], [300, 250])
        .build(),
    })

    if (slot && element) {
      if (story) {
        setAdTargeting(slot, story)
      }
      const div = document.createElement('div')
      div.className =
        'd-flex flex-column align-items-center story_dynamic_inread'
      const adId = slot.getSlotElementId()
      div.id = adId

      element.before(div)
      googletag.display(slot)

      googletag.pubads().addEventListener('slotRenderEnded', (event) => {
        if (event.slot.getSlotElementId() === adId) {
          if (!event.isEmpty) {
            div.className += ' my-5'
          }
        }
      })

      refreshAfterViewable(slot, 2)
    }
  })
}

export function setAdTargeting(
  slot: googletag.Slot,
  document: Story | PressRelease,
) {
  if (document?.category) {
    slot.setTargeting('glance_category', document.category)
  }

  if (document?.tags) {
    slot.setTargeting(
      'glance_tag',
      document.tags.map((tag) => tag.name),
    )
  }

  if (document?._type === 'story') {
    if (document?.organizations) {
      const orgIds = document.organizations
        .filter((org) => !!org.uuid)
        // casting as string since typescript can't infer it from the filter
        .map((org) => org.uuid) as string[]
      if (orgIds.length) {
        slot.setTargeting('glance_company', orgIds)
      }
      slot.setTargeting(
        'glance_company_slugs',
        document.organizations.map((org) => org.slug.current),
      )
    }
  }
}
