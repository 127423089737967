import {useContext} from 'react'

import Script from 'next/script'

import {PRComponentContext} from '../../press-release/PRComponent'

export default function GAViewedPR() {
  const {pressRelease, url} = useContext(PRComponentContext)

  const customDimensions = {
    event_category: 'glance-viewed_pressRelease',
    page_location: url,
    page_title: pressRelease.title,
    'glance-Category': pressRelease.category,
    'glance-Organizations': pressRelease.organizations?.map((org) => org.name),
  }

  // used for OutboundLinks script
  const GAPagePropertiesString = `
  window.GAPageProperties.dimension18 = '${pressRelease.category}'
  ${
    pressRelease.organizations
      ? `window.GAPageProperties.dimension20 = '${pressRelease.organizations.map(
          (org) => org.name,
        )}'`
      : ''
  }
  `

  return (
    pressRelease && (
      <Script
        id={`gtag-viewed-pressRelease-custom-dimensions_${pressRelease._id}`}
        type="text/plain"
        data-cookieconsent="statistics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              window.gtag && window.gtag('event', 'glance-track_custom_dimensions', ${JSON.stringify(
                customDimensions,
              )})
              if (window.GAPageProperties) {
                ${GAPagePropertiesString}
              }
            `,
        }}
      />
    )
  )
}
