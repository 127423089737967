import {z} from 'zod'

import Link from 'next/link'

import useHasMounted from '../../../lib/hooks/useHasMounted'
import {types} from '../../../lib/sanity'
import {displayPublishedDate} from '../../../lib/utils'
import {getStorySlug} from '../utils'

const StoriesBlockProps = z.object({
  heading: z.string().optional(),
  stories: types.StoriesSchema.optional(),
})

export default function StoriesBlock({
  heading,
  stories,
}: z.infer<typeof StoriesBlockProps>) {
  const hasMounted = useHasMounted()

  if (!stories || !stories.length) return <></>

  return (
    <>
      {heading && (
        <h6 className="font-nunito text-muted text-uppercase border-bottom pb-3 mb-4">
          {heading}
        </h6>
      )}
      <div className="row gy-2 mb-4">
        {stories.map((story: z.infer<typeof types.StorySchema>) => (
          <div key={story._id}>
            <Link
              href={getStorySlug(story.dateSlug, story.slug.current) as string}
              legacyBehavior
            >
              <a>
                <h6 className="mb-1">{story.title}</h6>
              </a>
            </Link>
            {story.dateSlug && hasMounted && (
              <small
                className="p-0 d-block text-muted"
                style={{fontSize: '0.9rem'}}
              >
                <span>{displayPublishedDate(story.dateSlug)}</span>
              </small>
            )}
          </div>
        ))}
      </div>
    </>
  )
}
