import {useContext} from 'react'

import Script from 'next/script'

import {StoryComponentContext} from '../../story/StoryComponent'

export default function MixpanelViewedStory() {
  const {story} = useContext(StoryComponentContext)

  const storyData = {
    'Story Category': story.category,
    'Story Slug': story.slug.current,
    'Story Tags': story.tags?.map((tag) => tag.name),
    'Story Author': story.authors?.map((author) => author.name),
    'Story Sponsor': story.sponsor?.uuid,
    'Story Organizations': story.organizations?.map((org) => org.name),
  }

  return (
    story && (
      <Script
        id={`mixpanel-viewed-story_${story._id}`}
        type="text/plain"
        data-cookieconsent="statistics "
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          if (window.mixpanel) {
            window.mixpanel.track('Viewed Story', ${JSON.stringify(storyData)});
          }
        `,
        }}
      />
    )
  )
}
