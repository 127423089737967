import {useContext} from 'react'

import {
  useCategoryPressReleases,
  useOrganizationPressReleases,
} from '../../../lib/sanity'
import CiteButton from '../../common/CiteButton'
import SocialShareButton from '../../common/SocialShareButton'
import {PRComponentContext} from '../PRComponent'
import PRBlock from './PRBlock'

export default function Sidebar() {
  const {pressRelease, url} = useContext(PRComponentContext)
  const {pressReleases: organizationPressReleases} =
    useOrganizationPressReleases({
      primaryPressRelease: pressRelease,
    })
  const {pressReleases: categoryPressReleases} = useCategoryPressReleases({
    primaryPressRelease: pressRelease,
  })

  return (
    <>
      <div className="sticky-top" style={{top: '110px', zIndex: 1}}>
        <div className="d-none d-lg-flex mb-4">
          <CiteButton
            title={pressRelease.title}
            url={url}
            publishedDate={new Date(Date.parse(pressRelease.publishedAt))}
          />
          <SocialShareButton title={pressRelease.title} url={url} />
        </div>
        {categoryPressReleases && (
          <PRBlock
            heading={pressRelease?.category}
            pressReleases={categoryPressReleases}
          />
        )}
        {organizationPressReleases?.map((pr, i) => (
          <PRBlock
            key={i}
            heading={pr.organization?.name}
            pressReleases={pr.pressReleases}
          />
        ))}
      </div>
    </>
  )
}
