import z from 'zod'

import Image from 'next/legacy/image'
import Link from 'next/link'

import {getImageSource} from '../../lib/sanity'
import {Author, SanityImageAsset} from '../../lib/sanity/types'
import {trpc} from '../../utils/trpc'

/**
 * Render a byline for an author.
 * The byline includes a link to the author's profile page and a profile picture.
 */
export const BylineComponent = ({author}: {author: Author}) => {
  return (
    <Link
      href={`https://eyesoneyecare.com/authors/${author.slug.current}/`}
      key={author._id}
      className="d-inline-flex align-items-center pr-3 me-3"
    >
      <ProfilePicture author={author} />
      <p className="ms-1 mb-0">{author.name}</p>
    </Link>
  )
}

/**
 * Render a profile picture for an author.
 *
 * Fetch the picture client side so that statically generated pages
 * don't get out of sync with the author's profile picture.
 *
 * Fallback to a placeholder icon if the picture is not available or loading
 * to avoid cumulative layout shift for SEO.
 */
const ProfilePicture = ({author}: {author: Author}) => {
  const useGetProfilePicture = trpc.sanity.getAuthorProfilePicture.useQuery({
    _id: author._id,
  })
  if (!useGetProfilePicture.data) return <PlaceholderProfilePicture />
  return (
    <Image
      src={getImageSource(
        useGetProfilePicture.data as z.infer<typeof SanityImageAsset>,
      )
        .width(25)
        .height(25)
        .url()}
      width={25}
      height={25}
      alt={author.name}
      className={'rounded-circle'}
    />
  )
}

/**
 * Circular gray person icon 25px x 25px
 * SVG from https://heroicons.com/
 */
const PlaceholderProfilePicture = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className={'text-secondary'}
      style={{width: '25px', height: '25px'}}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
      />
    </svg>
  )
}
