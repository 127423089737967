import groq from 'groq'
import {z} from 'zod'

import {getTrendingStoryIds} from '../analytics/requests'
import {
  AuthorSchemas,
  ProjectionValidator,
  cdnSanity,
  sanity,
  types,
} from './index'

const requiredImageFields = groq`{ 
  _type, asset->{ _id, metadata {dimensions} } 
}`
const requiredAuthorFields = groq`{ 
  _id, _type, name, slug, "profilePicture": profilePicture ${requiredImageFields} 
}`
const requiredOrganizationFields = groq`{ 
  _id, uuid, name, slug, "image": image ${requiredImageFields} 
}`
const requiredStoryFields = groq`{ 
  _id,
  _type,
  slug,
  category,
  containsLottie,
  disclosure,
  featured,
  "featuredImage": featuredImage ${requiredImageFields},
  authors[]->${requiredAuthorFields},
  body[] {
    ...,
    "image": image ${requiredImageFields},
    _type == "lottieFile" => {
      ...,
      "lottieUrl": lottieFile.asset->url
    },
    markDefs[] {
      ...,
      "logo": logo ${requiredImageFields},
      _type == "internalLink" => {
        "documentType": @.document->_type,
        "dateSlug": @.document->dateSlug,
        "slug": @.document->slug.current
      },
    }
  },
  featuredText,
  inReadAdIteration,
  showAds,
  showInReadAds,
  "stickyFooter": stickyFooter->{stickyFooterCTA, stickyFooterContent},
  dateSlug,
  publishedAt,
  sponsor->${requiredOrganizationFields},
  tags[]->,
  templateType,
  title,
  titleOverride,
  organizations[]->${requiredOrganizationFields},
  "estimatedReadTime": round(length(pt::text(body)) / 5 / 180 ),
  citations,
}`

const requiredPRFields = groq`{ 
  ..., 
  tags[]->,
  authors[]->{ ..., "profilePicture": profilePicture { ..., asset-> }},
  sponsor->{ ..., "image": image { ..., asset-> }},
  organizations[]->{ ..., "image": image { ..., asset-> }},
}`

/**
 * getAllStories queries Sanity for all stories and validates
 * the returned data against the Stories schema.
 */
export async function getAllStories() {
  const data = await sanity.fetch(
    groq`
    *[ 
      _type == "story"  
      && defined(slug.current)
      && !(_id in path('drafts.**')) 
    ] 
    ${requiredStoryFields}
    `,
  )
  return types.StoriesSchema.parse(data)
}

/**
 * 1. Query Sanity for all stories published in the last 30 days
 * 2. Validate query result against the Stories schema.
 */
export async function get30DayStories() {
  const currentDate = new Date()
  // Subtract 30 days from the current date
  currentDate.setDate(currentDate.getDate() - 30)

  const year = currentDate.getFullYear()

  // Get the month and add 1 because getMonth() returns 0-11 for Jan-Dec
  // Pad with a zero if necessary to ensure the month is two digits
  const month = String(currentDate.getMonth() + 1).padStart(2, '0')

  // Get the date and pad with a zero if necessary to ensure the date is two digits
  const day = String(currentDate.getDate()).padStart(2, '0')

  // Slug formatted date
  const dateSlug = `${year}-${month}-${day}`

  const data = await sanity.fetch(
    groq`
    *[ 
      _type == "story"  
      && defined(slug.current)
      && defined(dateSlug)
      && !(_id in path('drafts.**'))
      && dateSlug > ${dateSlug}
    ] 
    ${requiredStoryFields}
    `,
  )
  return types.StoriesSchema.parse(data)
}

/**
 * getMostRecentStory queries Sanity for the last published story and validates
 * the returned data against the Stories schema.
 */
export async function getMostRecentStory() {
  const data = await sanity.fetch(
    groq`*[ 
        _type == "story"  
        && defined(slug.current)
        && defined(dateSlug)
        && !(_id in path('drafts.**')) 
      ] | order(publishedAt desc) [0]
      ${requiredStoryFields}`,
  )
  return types.StorySchema.parse(data)
}

/**
 * getStoryBySlug queries Sanity for a single story based on the slug
 * and validates the returned data against the Story schema.
 * @param slug
 */
export async function getStoryBySlug(slug: string) {
  const data = await sanity.fetch(
    groq`*[
        _type == "story" 
        && slug.current == $slug
        && !(_id in path('drafts.**'))
      ] [0] 
      ${requiredStoryFields}`,
    {
      slug: slug,
    },
  )
  return types.StorySchema.parse(data)
}

/**
 * getStoryById queries Sanity for a single story based on the id
 * and validates the returned data against the Story schema.
 * @param id
 */
export async function getStoryById(id: string) {
  const data = await sanity.fetch(
    groq`*[
        _type == "story" 
        && _id == $id
        && !(_id in path('drafts.**'))
      ] [0] 
      ${requiredStoryFields}`,
    {
      id,
    },
  )
  return types.StorySchema.parse(data)
}

/**
 * getStoryPreviewById queries Sanity for a single story based on the _id. Intended
 * to be use for draft previews, so no schema validation is run (incomplete
 * documents are expected)
 * @param _id
 */
export async function getStoryPreviewById(_id: string) {
  return await sanity.fetch(
    groq`*[
        _type == 'story' &&
        _id == $_id
      ] [0] 
      ${requiredStoryFields}`,
    {
      _id,
    },
  )
}

/**
 * getPRPreviewById queries Sanity for a single PR based on the _id. Intended
 * to be use for draft previews, so no schema validation is run (incomplete
 * documents are expected)
 * @param _id
 */
export async function getPRPreviewById(_id: string) {
  return await sanity.fetch(
    groq`*[
        _type == 'pressRelease' &&
        _id == $_id
      ] [0] 
      ${requiredPRFields}`,
    {
      _id,
    },
  )
}

/**
 * getRecentStoriesByOrganization queries Sanity for stories related to an organization
 * and validates the returned data against the Stories schema.
 * @param organizationId
 * @param limit
 * @param ignoredStories
 */
export async function getRecentStoriesByOrganization(
  organizationId: string,
  limit = 3,
  ignoredStories: Array<string> = [],
  useCdn = false,
) {
  const sanityClient = useCdn ? cdnSanity : sanity
  const data = await sanityClient.fetch(
    groq`*[ 
        _type == 'story' 
        && defined(slug.current) 
        && defined(dateSlug)
        && !(_id in path('drafts.**'))
        && !(_id in $ignoredStories)
        && references($organizationId) 
    ] | order(publishedAt desc) [0...$limit] 
    ${requiredStoryFields}`,
    {
      organizationId,
      limit,
      ignoredStories,
    },
  )
  return types.StoriesSchema.parse(data)
}

/**
 * getRecentStoriesByCategory queries Sanity for stories related to a category
 * and validates the returned data against the Stories schema.
 * @param category
 * @param limit
 * @param ignoredStories
 */
export async function getRecentStoriesByCategory(
  category: string,
  limit = 3,
  ignoredStories: Array<string> = [],
  useCdn = false,
) {
  const sanityClient = useCdn ? cdnSanity : sanity
  const data = await sanityClient.fetch(
    groq`*[ 
        _type == 'story' 
        && defined(slug.current) 
        && defined(dateSlug)
        && !(_id in path('drafts.**'))
        && !(_id in $ignoredStories)
        && category == $category 
    ] | order(publishedAt desc) [0...$limit] 
    ${requiredStoryFields}`,
    {
      category,
      limit,
      ignoredStories,
    },
  )
  return types.StoriesSchema.parse(data)
}

/**
 * getRecentStoriesByTag queries Sanity for stories related to a tag
 * and validates the returned data against the Stories schema.
 * @param tag
 * @param limit
 * @param ignoredStories
 */
export async function getRecentStoriesByTag(
  tag: string,
  limit = 3,
  ignoredStories: Array<string> = [],
  useCdn = false,
) {
  const sanityClient = useCdn ? cdnSanity : sanity
  const data = await sanityClient.fetch(
    groq`*[ 
        _type == 'story' 
        && defined(slug.current) 
        && defined(dateSlug)
        && !(_id in path('drafts.**'))
        && !(_id in $ignoredStories)
        && $tag in tags[]->name
    ] | order(publishedAt desc) [0...$limit] 
    ${requiredStoryFields}`,
    {
      tag,
      limit,
      ignoredStories,
    },
  )
  return types.StoriesSchema.parse(data || [])
}

/**
 * getRecentStories queries Sanity for stories ordered by most recent
 * @param limit
 */
export async function getHeadlineStories(
  limit = 8,
  ignoredStories: Array<string> = [],
  useCdn = false,
) {
  const sanityClient = useCdn ? cdnSanity : sanity
  const data = await sanityClient.fetch(
    groq`*[ 
        _type == "story"  
        && defined(slug.current)
        && defined(dateSlug)
        && !(_id in path('drafts.**')) 
        && !(_id in $ignoredStories)
        && featured != true
      ] | order(publishedAt desc) [0...$limit]
      ${requiredStoryFields}`,
    {
      limit,
      ignoredStories,
    },
  )
  return types.StoriesSchema.parse(data)
}

// Options to pass to getAuthor if we have access to Sanity _ids
type AuthorIDOpts = {
  id: string
  slug?: never
  schema: ProjectionValidator
}
// Options to pass to getAuthor if we have access to Sanity slugs (e.g. from the URL)
type AuthorSlugOpts = {
  id?: never
  slug: string
  schema: ProjectionValidator
}
// Only use _ids or slugs, not both
type GetAuthorOpts = AuthorIDOpts | AuthorSlugOpts

/**
 * Query sanity for an author by either _id or slug
 * Validate the returned projection data against the provided schema
 */
export async function getAuthor(opts: GetAuthorOpts): Promise<AuthorSchemas> {
  const {id, slug, schema} = opts
  const data = await sanity.fetch(
    groq`*[
      _type == "author"
      && ${id ? `_id == $input` : `slug.current == $input`}
    ][0]${schema.projection}`,
    {
      input: id || slug,
    },
  )
  return schema.validator.parse(data)
}

/**
 * getFeaturedStory queries Sanity for the latest featured story
 */
export async function getFeaturedStory(useCdn = false) {
  const sanityClient = useCdn ? cdnSanity : sanity
  const data = await sanityClient.fetch(
    groq`*[ 
        _type == "story"  
        && defined(slug.current)
        && defined(dateSlug)
        && !(_id in path('drafts.**')) 
        && featured == true
      ] | order(publishedAt desc) [0] 
      ${requiredStoryFields}`,
  )
  if (!data) return null // No featured stories have been published
  return types.StorySchema.parse(data)
}

/**
 * getAllPressReleases queries Sanity for all press releases and validates
 * the result against the PressReleases schema.
 */
export async function getAllPressReleases() {
  const data = await sanity.fetch(
    groq`*[ 
        _type == "pressRelease"  
        && defined(slug.current)
        && !(_id in path('drafts.**')) 
      ] 
      { 
        ..., 
        tags[]->,
        authors[]->{ ..., "profilePicture": profilePicture { ..., asset-> }},
        sponsor->{ ..., "image": image { ..., asset-> }},
        organizations[]->{ ..., "image": image { ..., asset-> }},
      }`,
  )
  return types.PressReleasesSchema.parse(data)
}

/**
 * getPressReleaseBySlug queries Sanity for a press release based on the slug
 * and validates the returned data against the PressRelease schema.
 * @param slug
 */
export async function getPressReleaseBySlug(slug: string) {
  const data = await sanity.fetch(
    groq`*[
        _type == "pressRelease"
        && slug.current == $slug
        && !(_id in path('drafts.**'))
      ] [0] 
      { 
        ..., 
        tags[]->,
        authors[]->${requiredAuthorFields},
        sponsor->${requiredOrganizationFields},
        organizations[]->${requiredOrganizationFields},
        body[] {
          ...,
          "image": image ${requiredImageFields},
          markDefs[] {
            ...,
            _type == "internalLink" => {
              "documentType": @.document->_type,
              "dateSlug": @.document->dateSlug,
              "slug": @.document->slug.current
            }
          }
        },
      }`,
    {
      slug: slug,
    },
  )
  return types.PressReleaseSchema.parse(data)
}

/**
 * Get any Sanity document by its ID
 * Pass in a zod schema to optionally validate the result
 *
 * @param id
 * @param validationSchema
 */
export async function getSanityDocumentById(
  id: string,
  validationSchema: z.AnyZodObject | undefined = undefined,
) {
  const data = await sanity.getDocument(id)
  if (validationSchema) {
    return validationSchema.parse(data)
  }
  return data
}

/**
 * Get trending stories.
 * Call Django to get trending story IDs, then call Sanity to get the story content
 * and relations.
 */
export async function getTrendingStories() {
  const req = await getTrendingStoryIds()
  const res = await req.json()
  if (res.trending) {
    const trendingStories = await Promise.all(
      res.trending.map(async (trendingId: string) => {
        try {
          return await getStoryById(trendingId)
        } catch (e) {
          // Nothing to do here. We don't want to fail the entire request if one story fails.
        }
      }),
    )
    if (trendingStories) {
      return trendingStories.filter(
        (trendingStory) => trendingStory !== undefined,
      )
    }
  }
  return [] as types.Story[]
}

/**
 * getRecentPressReleasesByOrganization queries Sanity for press releases related to an organization
 * and validates the returned data against the PressReleases schema.
 * @param organizationId
 * @param limit
 * @param ignoredPressReleases
 */
export async function getRecentPressReleasesByOrganization(
  organizationId: string,
  limit = 3,
  ignoredPressReleases: Array<string> = [],
  useCdn = false,
) {
  const sanityClient = useCdn ? cdnSanity : sanity
  const data = await sanityClient.fetch(
    groq`*[ 
        _type == 'pressRelease' 
        && defined(slug.current) 
        && !(_id in path('drafts.**'))
        && !(_id in $ignoredPressReleases)
        && references($organizationId) 
    ] | order(publishedAt desc) [0...$limit] 
    { 
      ..., 
      tags[]->,
      authors[]->{ ..., "profilePicture": profilePicture { ..., asset-> }},
      sponsor->{ ..., "image": image { ..., asset-> }},
      organizations[]->{ ..., "image": image { ..., asset-> }},
      "featuredImage": featuredImage { ..., asset-> },
      "estimatedReadTime": round(length(pt::text(body)) / 5 / 180 ),
      body[] {
        ...,
        "image": image { ..., asset-> },
        markDefs[] {
          ...,
          "logo": logo { ..., asset-> },
        }
      }
    }`,
    {
      organizationId,
      limit,
      ignoredPressReleases,
    },
  )
  return types.PressReleasesSchema.parse(data)
}

/**
 * getRecentPressReleasesByCategory queries Sanity for press releases related to a category
 * and validates the returned data against the PressReleases schema.
 * @param category
 * @param limit
 * @param ignoredPressReleases
 */
export async function getRecentPressReleasesByCategory(
  category: string,
  limit = 3,
  ignoredPressReleases: Array<string> = [],
  useCdn = false,
) {
  const sanityClient = useCdn ? cdnSanity : sanity
  const data = await sanityClient.fetch(
    groq`*[ 
        _type == 'pressRelease' 
        && defined(slug.current) 
        && !(_id in path('drafts.**'))
        && !(_id in $ignoredPressReleases)
        && category == $category 
    ] | order(publishedAt desc) [0...$limit] 
    { 
      ..., 
      tags[]->,
      authors[]->{ ..., "profilePicture": profilePicture { ..., asset-> }},
      sponsor->{ ..., "image": image { ..., asset-> }},
      organizations[]->{ ..., "image": image { ..., asset-> }},
      "featuredImage": featuredImage { ..., asset-> },
      "estimatedReadTime": round(length(pt::text(body)) / 5 / 180 ),
      body[] {
        ...,
        "image": image { ..., asset-> },
        markDefs[] {
          ...,
          "logo": logo { ..., asset-> },
        }
      } 
    }`,
    {
      category,
      limit,
      ignoredPressReleases,
    },
  )
  return types.PressReleasesSchema.parse(data)
}

/**
 * getRecentPressReleases queries Sanity for press releases ordered by most recent
 * @param limit
 */
export async function getHeadlinePressReleases(
  limit = 8,
  ignoredPressReleases: Array<string> = [],
  useCdn = false,
) {
  const sanityClient = useCdn ? cdnSanity : sanity
  const data = await sanityClient.fetch(
    groq`*[ 
        _type == "pressRelease"  
        && defined(slug.current)
        && !(_id in path('drafts.**')) 
        && !(_id in $ignoredPressReleases)
      ] | order(publishedAt desc) [0...$limit] 
      { 
        ..., 
        tags[]->,
        authors[]->{ ..., "profilePicture": profilePicture { ..., asset-> }},
        sponsor->{ ..., "image": image { ..., asset-> }},
        organizations[]->{ ..., "image": image { ..., asset-> }},
        "featuredImage": featuredImage { ..., asset-> },
        "estimatedReadTime": round(length(pt::text(body)) / 5 / 180 ),
        body[] {
          ...,
          "image": image { ..., asset-> },
          markDefs[] {
            ...,
            "logo": logo { ..., asset-> },
          }
        }
      }`,
    {
      limit,
      ignoredPressReleases,
    },
  )
  return types.PressReleasesSchema.parse(data)
}

/**
 * Query Sanity for all organizations that are referenced by stories
 */
export async function getReferencedOrganizations() {
  const data = await sanity.fetch(
    groq`
    *[_type == 'organization'] {
      "organization": ${requiredOrganizationFields},
      "isReferenced": count(*[_type == 'story' && references(^._id)]) >= 1
    } [isReferenced].organization
    `,
  )
  return types.OrganizationsSchema.parse(data)
}

/**
 * Query Sanity for a single organization by slug
 */
export async function getOrganizationBySlug(slug: string) {
  const data = await sanity.fetch(
    groq`*[
      _type == 'organization' && slug.current == $slug
    ] {_id, uuid, name, slug, "image": image ${requiredImageFields}, description, website } [0]`,
    {slug},
  )
  return types.OrganizationSchema.parse(data)
}

/**
 * Query Sanity for a referenced document's fields by document type and reference id
 */
export async function getReferenceData(
  documentType: string,
  referenceId: string,
) {
  const data = await sanity.fetch(
    groq`*[_type == $documentType && _id == $referenceId][0]`,
    {documentType, referenceId},
  )
  return data
}
