import {baseUrl} from '../../lib/constants'
import {getImageSource} from '../../lib/sanity'
import {PressRelease, Story} from '../../lib/sanity/types'
import {getStorySlug} from '../story/utils'

interface ParselyStoryMetadataProps {
  story: Story
}

interface ParselyPRMetadataProps {
  pressRelease: PressRelease
}

interface ParselyGenericMetadataProps {
  url: string
  title: string
  section?: string
}

export function ParselyStoryMetadata({story}: ParselyStoryMetadataProps) {
  const imgSrc = story.featuredImage
    ? getImageSource(story.featuredImage.asset).url()
    : null
  // Add some additional tags to enable Parsely filtering/reporting
  const tags = story.tags ? [...story.tags.map((tag) => tag.name)] : []
  tags.push(`glance/category/${story.category}`)
  if (story.sponsor) {
    tags.push(`glance/sponsor/${story.sponsor.name}`)
  }
  story.organizations?.map((org) =>
    tags.push(`glance/organization/${org.name}`),
  )
  return (
    <>
      {story.authors?.map((author, i) => (
        <meta key={i} name="parsely-author" content={author.name} />
      ))}
      {imgSrc && <meta name="parsely-image-url" content={imgSrc} />}
      <meta
        name="parsely-link"
        content={`${baseUrl}/${getStorySlug(
          story.dateSlug,
          story.slug.current,
        )}`}
      />
      <meta name="parsely-pub-date" content={story.publishedAt} />
      <meta name="parsely-section" content="Glance" />
      <meta name="parsely-tags" content={tags.join(',')} />
      <meta name="parsely-title" content={story.title} />
      <meta name="parsely-type" content="post" />
    </>
  )
}

export function ParselyPRMetadata({pressRelease}: ParselyPRMetadataProps) {
  // Add some additional tags to enable Parsely filtering/reporting
  const tags = pressRelease.tags
    ? [...pressRelease.tags.map((tag) => tag.name)]
    : []
  tags.push(`glance/category/${pressRelease.category}`)
  pressRelease.organizations?.map((org) =>
    tags.push(`glance/organization/${org.name}`),
  )
  return (
    <>
      {pressRelease.authors?.map((author, i) => (
        <meta key={i} name="parsely-author" content={author.name} />
      ))}
      <meta
        name="parsely-link"
        content={`${baseUrl}/press-releases/${pressRelease.slug}/`}
      />
      <meta name="parsely-pub-date" content={pressRelease.publishedAt} />
      <meta name="parsely-section" content="Glance" />
      <meta name="parsely-tags" content={tags.join(',')} />
      <meta name="parsely-title" content={pressRelease.title} />
      <meta name="parsely-type" content="post" />
    </>
  )
}

export function ParselyMetadata({
  url,
  title,
  section,
}: ParselyGenericMetadataProps) {
  return (
    <>
      <meta name="parsely-link" content={url} />
      <meta name="parsely-title" content={title} />
      <meta name="parsely-type" content="index" />
      {section && <meta name="parsely-section" content={section} />}
    </>
  )
}
