import {useContext} from 'react'

import {faCalendar} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import useHasMounted from '../../../lib/hooks/useHasMounted'
import {displayPublishedDate} from '../../../lib/utils'
import {PRComponentContext} from '../PRComponent'
import BannerAd from '../../ads/BannerAd'

export default function PRHeader() {
  const {pressRelease} = useContext(PRComponentContext)
  const hasMounted = useHasMounted()

  // TODO:
  // const [hits, setHits] = useState(null);
  // useQuery('hits', getStoryHits(pressRelease.slug), {
  //   refetchOnWindowFocus: false,
  //   onSuccess: (data) => setHits(data.hits),
  // })

  if (!pressRelease) return <></>

  return (
    <div className="mb-4">
      <div className="mb-3">
        <BannerAd adCode="glance_story_header" document={pressRelease} />
      </div>
      {pressRelease.category && (
        <div className="text-uppercase col d-flex align-items-center mt-2 mt-md-0">
          <span style={{fontSize: '.8rem'}}>
            Published in{' '}
            <a
              className="text-primary"
              href={`/stories/?category=${pressRelease.category}`}
            >
              {pressRelease.category}
            </a>
          </span>
        </div>
      )}
      <h1>{pressRelease.title}</h1>
      <div
        className="d-flex align-items-center flex-wrap mb-1"
        style={{gap: '0.25rem'}}
      ></div>
      <div
        className="d-flex flex-wrap p-1 mb-3 border-top border-bottom text-gray-800 blocked"
        style={{fontSize: '.8rem'}}
      >
        {hasMounted && (
          <div className="d-flex align-items-center me-4">
            <FontAwesomeIcon icon={faCalendar} className="me-2 text-gray-500" />
            <span>{displayPublishedDate(pressRelease.publishedAt)}</span>
          </div>
        )}
        {/* {hasMounted && hits >= 100 && (
          <div className="d-flex align-items-center">
            <span>
              <FontAwesomeIcon icon={faEye} className="me-2 text-gray-500" />
              {displayHitCount(hits)} views
            </span>
          </div>
        )} */}
      </div>
    </div>
  )
}
