import {useContext} from 'react'

import Script from 'next/script'

import {PRComponentContext} from '../../press-release/PRComponent'

export default function MixpanelViewedPR() {
  const {pressRelease} = useContext(PRComponentContext)

  const pressReleaseData = {
    'Press Release Category': pressRelease.category,
    'Press Release Slug': pressRelease.slug.current,
    'Press Release Tags': pressRelease.tags?.map((tag) => tag.name),
    'Press Release Author': pressRelease.authors?.map((author) => author.name),
    'Press Release Organizations': pressRelease.organizations?.map(
      (org) => org.name,
    ),
  }

  return (
    pressRelease && (
      <Script
        id={`mixpanel-viewed-pressRelease_${pressRelease._id}`}
        type="text/plain"
        data-cookieconsent="statistics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          if (window.mixpanel) {
            window.mixpanel.track('Viewed Press Release', ${JSON.stringify(
              pressReleaseData,
            )});
          }
        `,
        }}
      />
    )
  )
}
