import {useContext, useState} from 'react'
import Collapse from 'react-bootstrap/Collapse'

import {faChevronDown, faChevronUp} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {PortableText} from '@portabletext/react'

import PortableTextComponents from '../../../lib/sanity/portableTextComponents'
import {StoryComponentContext} from '../StoryComponent'

export default function Citations() {
  const {story} = useContext(StoryComponentContext)
  const [open, setOpen] = useState(false)
  if (!story?.citations) {
    return <></>
  }
  return (
    <div className="my-4">
      <div className="card">
        <div className="card-body">
          <div
            className="d-flex justify-content-between align-items-center"
            role="button"
            aria-expanded={open}
            tabIndex={0}
            onClick={() => setOpen(!open)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setOpen(!open)
              }
            }}
          >
            <span className="h5 m-0">References</span>
            <FontAwesomeIcon
              role="button"
              icon={open ? faChevronUp : faChevronDown}
              className="text-primary"
            />
          </div>
          <Collapse in={open}>
            <div className="mt-3 break-word">
              <PortableText
                value={story.citations}
                components={PortableTextComponents}
              />
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  )
}
