import {useContext} from 'react'

import {useCategoryStories, useOrganizationStories} from '../../../lib/sanity'
import CiteButton from '../../common/CiteButton'
import SocialShareButton from '../../common/SocialShareButton'
import {StoryComponentContext} from '../StoryComponent'
import {BookmarkButton} from '../social/BookmarkButton'
import {LikeButton} from '../social/LikeButton'
import StoriesBlock from './StoriesBlock'

export default function Sidebar() {
  const {story, url} = useContext(StoryComponentContext)
  const {stories: organizationStories} = useOrganizationStories({
    primaryStory: story,
  })
  const {stories: categoryStories} = useCategoryStories({
    primaryStory: story,
  })

  return (
    <>
      <div className="sticky-top" style={{top: '110px', zIndex: 1}}>
        <div className="d-none d-lg-flex mb-4">
          <LikeButton />
          <BookmarkButton />
          <CiteButton
            title={story.title}
            url={url}
            publishedDate={new Date(Date.parse(story.dateSlug))}
          />
          <SocialShareButton title={story.title} url={url} />
        </div>
        {categoryStories && (
          <StoriesBlock heading={story?.category} stories={categoryStories} />
        )}
        {organizationStories?.map((story, i) => (
          <StoriesBlock
            key={i}
            heading={story.organization?.name}
            stories={story.stories}
          />
        ))}
      </div>
    </>
  )
}
