import {ANALYTICS_ENABLED} from '../../../lib/analytics/appAnalytics'
import GAViewedPR from './GAViewedPR'
import MixpanelViewedPR from './MixpanelViewedPR'

export default function PRAnalytics() {
  if (!ANALYTICS_ENABLED) return null

  return (
    <>
      <GAViewedPR />
      <MixpanelViewedPR />
    </>
  )
}
