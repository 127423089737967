import {useContext, useEffect, useState} from 'react'

import {useViewportDimensions} from '../../../lib/hooks/useViewportDimensions'
import CiteButton from '../../common/CiteButton'
import EditInSanityButton from '../../common/EditInSanityButton'
import SocialShareButton from '../../common/SocialShareButton'
import {StoryComponentContext} from '../StoryComponent'
import Citations from '../common/Citations'
import Sidebar from '../common/Sidebar'
import StoryBody from '../common/StoryBody'
import StoryHeader from '../common/StoryHeader'
import {BookmarkButton} from '../social/BookmarkButton'
import {LikeButton} from '../social/LikeButton'
import StickyFooter from '../common/StickyFooter'
import {RatingWidget} from '../social/RatingWidget'

export default function DefaultTemplate() {
  const {story, url} = useContext(StoryComponentContext)

  /**
   * Set the height of the component to the viewport height so that short
   * content doesn't get crammed together.
   */
  const {height: viewportHeight} = useViewportDimensions()
  const [height, setHeight] = useState(`${viewportHeight}px`)
  useEffect(() => {
    // Subtract the height of the navbar (83px) and then some more to make the user want to scroll 😈
    setHeight(`${viewportHeight - 83 - 200}px`)
  }, [viewportHeight])

  return (
    <div style={{minHeight: height}}>
      {/* Temporarily disable in-reads - overkill for short-form content */}
      {/* <StoryInReadAds /> */}
      <div className="container">
        <EditInSanityButton sanityId={story._id} sanityType="story" />
        <div className="row">
          <div className="col-lg-8">
            <StoryHeader />
            <StoryBody />
            <Citations />
            <RatingWidget />
            <div className="d-flex d-lg-none my-3">
              <LikeButton />
              <BookmarkButton />
              <CiteButton
                title={story.title}
                url={url}
                publishedDate={new Date(Date.parse(story.dateSlug))}
              />
              <SocialShareButton title={story.title} url={url} />
            </div>
          </div>
          <div className="col-lg-4 mt-3 mt-lg-0">
            <Sidebar />
          </div>
        </div>
        <StickyFooter />
      </div>
    </div>
  )
}
