import {z} from 'zod'

import Head from 'next/head'

const MetadataProps = z.object({
  title: z.string().optional(),
  url: z.string().optional(),
  description: z.string().optional(),
  imgSrc: z.string().optional(),
  type: z.string().optional(),
})

export default function Metadata({
  title,
  url,
  description,
  imgSrc,
  type,
}: z.infer<typeof MetadataProps>) {
  return (
    <Head>
      <meta name="format-detection" content="telephone=no"></meta>
      {title && (
        <>
          <title>{title}</title>
          <meta property="og:title" content={title} key="og:title" />
          <meta name="twitter:title" content={title} key="twitter:title" />
        </>
      )}
      {url && (
        <>
          <link href={url} key="canonical" rel="canonical" />
          <meta property="og:url" content={url} key="og:url" />
          <meta property="twitter:url" content={url} key="twitter:url" />
        </>
      )}
      {description && (
        <>
          <meta name="description" key="description" content={description} />
          <meta
            content={description}
            key="og:description"
            property="og:description"
          />
          <meta
            content={description}
            key="twitter:description"
            name="twitter:description"
          />
        </>
      )}
      {imgSrc && (
        <>
          <meta property="og:image" content={imgSrc} key="og:image" />
          <meta name="twitter:image" content={imgSrc} key="twitter:image" />
        </>
      )}
      {type && <meta property="og:type" content={type} key="og:type" />}
      <meta
        content="summary_large_image"
        key="twitter:card"
        name="twitter:card"
      />
      <meta
        content="https://glance.eyesoneyecare.com/"
        key="twitter:domain"
        property="twitter:domain"
      />
    </Head>
  )
}
