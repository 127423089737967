import {useContext} from 'react'

import {PortableText} from '@portabletext/react'

import PortableTextComponents from '../../../lib/sanity/portableTextComponents'
import {PRComponentContext} from '../PRComponent'

export default function PRBody() {
  const {pressRelease} = useContext(PRComponentContext)

  return (
    <div className="pressRelease-body break-word">
      {pressRelease?.body && (
        <div className="my-3 text-body">
          <article id={`pressRelease-body__${pressRelease._id}`}>
            <PortableText
              value={pressRelease.body}
              components={PortableTextComponents}
            />
          </article>
        </div>
      )}
    </div>
  )
}
