import {useEffect, useState} from 'react'
import ReactPlayer from 'react-player'

type VideoEmbedBlockProps = {
  url: string
}
function VideoEmbedBlock(props: VideoEmbedBlockProps) {
  const [hasWindow, setHasWindow] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true)
    }
  }, [])

  return (
    <div className="mb-3">
      {hasWindow && <ReactPlayer url={props.url} controls width="100%" />}
    </div>
  )
}
export default VideoEmbedBlock
