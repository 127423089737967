import {z} from 'zod'

import Image from 'next/legacy/image'

import {getFormattedImage} from '../../lib/sanity'
import {SanityImageAsset} from '../../lib/sanity/types'

const SanityImageComponentProps = z.object({
  src: SanityImageAsset.optional().nullable(),
  alt: z.string(),
  width: z.number(),
  height: z.number(),
  className: z.string().optional(),
  objectFit: z
    .enum(['fill', 'contain', 'cover', 'none', 'scale-down'])
    .optional(),
  priority: z.boolean().optional(),
})

export default function SanityImageComponent({
  src,
  alt,
  width,
  height,
  className,
  objectFit = 'cover',
  priority = false,
}: z.infer<typeof SanityImageComponentProps>) {
  return (
    <Image
      src={getFormattedImage(src, width * 2)}
      alt={alt}
      width={width}
      height={height}
      className={className}
      objectFit={objectFit}
      layout="intrinsic"
      priority={priority}
    />
  )
}
