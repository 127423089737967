import {useContext} from 'react'

import CiteButton from '../../common/CiteButton'
import EditInSanityButton from '../../common/EditInSanityButton'
import SocialShareButton from '../../common/SocialShareButton'
import {PRComponentContext} from '../PRComponent'
import PRBody from '../common/PRBody'
import PRHeader from '../common/PRHeader'
import Sidebar from '../common/Sidebar'

export default function DefaultTemplate() {
  const {pressRelease, url} = useContext(PRComponentContext)
  return (
    <>
      {/* Temporarily disable in-reads - overkill for short-form content */}
      {/* <StoryInReadAds /> */}
      <div className="container">
        <EditInSanityButton
          sanityId={pressRelease._id}
          sanityType="pressRelease"
        />
        <div className="row">
          <div className="col-lg-8">
            <PRHeader />
            <PRBody />
            <div className="d-flex d-lg-none my-3">
              <CiteButton
                title={pressRelease.title}
                url={url}
                publishedDate={new Date(Date.parse(pressRelease.publishedAt))}
              />
              <SocialShareButton title={pressRelease.title} url={url} />
            </div>
          </div>
          <div className="col-lg-4 mt-3 mt-lg-0">
            <Sidebar />
          </div>
        </div>
      </div>
    </>
  )
}
