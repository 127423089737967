import Link from 'next/link'

import {PortableText, PortableTextReactComponents} from '@portabletext/react'
import {getImageSource} from '.'
import Carousel from '../../components/images/Carousel'
import ImageComponent from '../../components/images/ImageComponent'
import {getPRSlug} from '../../components/press-release/utils'
import {CalloutBlock} from '../../components/story/blocks/CalloutBlock'
import LottieFile from '../../components/story/blocks/LottieFile'
import VideoEmbedBlock from '../../components/story/blocks/VideoEmbedBlock'
import {getStorySlug} from '../../components/story/utils'

/**
 * Serializer components to render Sanity Portable Text
 *
 * The props that are passed into these components are defined in the void.
 * I have no idea how to track down where these props are defined in
 * the Sanity ecosystem.
 */
const PortableTextComponents: Partial<PortableTextReactComponents> = {
  marks: {
    superscript: ({children}) => {
      return <sup>{children}</sup>
    },
    superduperscript: ({children}) => {
      return (
        <sup>
          <sup>{children}</sup>
        </sup>
      )
    },
    subscript: ({children}) => {
      return <sub>{children}</sub>
    },
    link: ({children, value}) =>
      value.blank ? (
        <a
          href={value.href}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary text-decoration-underline"
        >
          {children}
        </a>
      ) : (
        <a href={value.href} className="text-primary text-decoration-underline">
          {children}
        </a>
      ),
    internalLink: ({children, value}) => {
      interface ValueProps {
        blank: boolean
        documentType: 'story' | 'pressRelease'
        dateSlug: string
        slug: string
      }
      const {blank, documentType, slug, dateSlug}: ValueProps = value
      const url = {
        story: getStorySlug(dateSlug, slug),
        pressRelease: getPRSlug(slug),
      }

      if (blank) {
        return (
          <Link
            href={url[documentType]}
            className="text-primary text-decoration-underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </Link>
        )
      } else {
        return (
          <Link
            href={url[documentType]}
            className="text-primary text-decoration-underline"
          >
            {children}
          </Link>
        )
      }
    },
    anchorLink: ({children, value}) => (
      <a href={`#${value.link}`} className="text-decoration-underline">
        {children}
      </a>
    ),
    anchorId: ({children, value}) => <span id={`${value.id}`}>{children}</span>,
    inlineHighlight: ({children}) => {
      return <mark>{children}</mark>
    },
    callout: (props) => {
      return (
        <CalloutBlock
          portableTextChildren={props.children}
          referenceId={props.value.calloutReference?._ref}
        />
      )
    },
    indent: ({children}) => {
      return (
        <span
          style={{
            display: 'block',
            listStyleType: 'none',
            paddingInlineStart: '1rem',
          }}
        >
          {children}
        </span>
      )
    },
  },
  types: {
    highlight: ({value}) => {
      return (
        <div className={`alert alert-${value.color} pb-0`}>
          <PortableText value={value.body} />
        </div>
      )
    },
    table: ({value}) => (
      <div className="table-responsive">
        <table className="table" style={{wordBreak: 'normal'}}>
          <thead>
            <tr>
              {value.rows[0].cells.map((cell: string, i: number) => (
                <th key={i} scope="col">
                  {cell}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {value.rows.slice(1).map((row: {_key: string; cells: [string]}) => (
              <tr key={row._key}>
                {row.cells.map((cell: string, i) => (
                  <td key={`${row._key}_${i}`}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ),
    linkableImage: ({value}) => {
      return (
        <ImageComponent
          src={getImageSource(value.image).url()}
          width={value.image.asset.metadata.dimensions.width}
          height={value.image.asset.metadata.dimensions.height}
          link={value.link}
          alt={value.title}
          fancyboxId={value._key}
        />
      )
    },
    imageGallery: ({value}) => {
      return <Carousel _key={value._key} images={value.images} />
    },
    lottieFile: ({value}) => {
      return <LottieFile {...value} />
    },
    documentEmbed: ({value}) => {
      return (
        <div className="mb-3">
          <a
            href={value.url}
            target="_blank"
            className="text-primary text-decoration-underline"
            rel="noreferrer"
          >
            Download
          </a>
        </div>
      )
    },
    videoEmbed: ({value}) => {
      return <VideoEmbedBlock url={value.url} />
    },
  },
}
export default PortableTextComponents

export const FeaturedTextComponents: Partial<PortableTextReactComponents> = {
  marks: {
    link: (props) =>
      props.value.blank ? (
        <a
          href={props.value.href}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary text-decoration-underline"
        >
          {props.children}
        </a>
      ) : (
        <a
          href={props.value.href}
          className="text-primary text-decoration-underline"
        >
          {props.children}
        </a>
      ),
    superscript: ({children}) => <sup>{children}</sup>,
    superduperscript: ({children}) => {
      return (
        <sup>
          <sup>{children}</sup>
        </sup>
      )
    },
    subscript: ({children}) => <sub>{children}</sub>,
  },
}
