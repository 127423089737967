import {useContext} from 'react'

import {faBookmark} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {AuthenticationContext} from '../../../lib/auth'
import {StoryComponentContext} from '../StoryComponent'

export const BookmarkButton = () => {
  const {hasBookmarked, bookmark, unBookmark} = useContext(
    StoryComponentContext,
  )
  const {user, openAuthModal} = useContext(AuthenticationContext)
  const handleClick = () => {
    if (!user) {
      openAuthModal()
      return
    }
    if (hasBookmarked) {
      unBookmark()
    } else {
      bookmark()
    }
  }
  return (
    <button
      onClick={handleClick}
      className={`btn d-flex me-2 ${
        hasBookmarked ? 'btn-primary' : 'btn-outline-primary'
      } btn-md d-flex justify-content-center align-items-center me-2 py-1 flex-grow-1 flex-lg-grow-0`}
    >
      <FontAwesomeIcon icon={faBookmark} className="me-0 me-sm-2" size="lg" />
      <span className="d-none d-sm-block">Save</span>
    </button>
  )
}
