import {baseUrl} from '../../lib/constants'
import DefaultTemplate from './template/DefaultTemplate'

export function getTemplate(templateType: 'default') {
  return {
    default: DefaultTemplate,
  }[templateType ?? 'default']
}

export function getDisclosure(
  disclosureType: string | undefined,
  sponsor: string | undefined,
) {
  switch (disclosureType) {
    case 'default':
      return 'This is editorially independent content'
    case 'advertising':
      return 'This is editorially independent content supported by advertising'
    case 'sponsor':
      return 'This post is sponsored by ' + sponsor
    case 'sponsorAdvertising':
      return (
        'This is editorially independent content supported by advertising from ' +
        sponsor
      )
    default:
      return ''
  }
}

export function getStorySlug(dateSlug: string, slug: string) {
  return `/stories/${dateSlug}/${slug}/`
}

export function getStoryUrl(dateSlug: string, slug: string) {
  return `${baseUrl}/stories/${dateSlug}/${slug}/`
}

export function getSlugFromUrl(url: string) {
  const dateSlugExtra = url.split('/stories/')[1]
  const storySlug = dateSlugExtra?.split('/')[1]
  return storySlug ?? ''
}
