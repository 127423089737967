import {z} from 'zod'

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import {AuthenticationContext} from '../../lib/auth'
import {Story, StorySchema} from '../../lib/sanity/types'
import {trpc} from '../../utils/trpc'
import StoryAnalytics from '../appAnalytics/story/StoryAnalytics'
import {getStoryUrl, getTemplate} from './utils'

const StoryComponent = (props: {
  story: Story
  isVisible: boolean
  preview?: boolean
}) => {
  const {story, isVisible, preview} = props
  const Template = getTemplate(story.templateType)

  return (
    <StoryComponentProvider
      story={story}
      isVisible={isVisible}
      preview={preview}
    >
      <StoryAnalytics />
      <Template />
    </StoryComponentProvider>
  )
}
export default StoryComponent

/**
 * StoryComponentProvider gives children access to the story's context.
 *  - Whether the user has liked, bookmarked, or viewed the story
 *  - Functions to like, bookmark, or mark the story as viewed
 *  - The story's URL
 *
 * Use `disableAnalytics` to prevent POSTing hits and views on mount.
 *
 * This component fetches the story's analytics from the server and logs a page view on mount.
 *
 * Usage:
 * const {
 *   story,
 *   url,
 *   hasLiked,
 *   like,
 *   unlike,
 *   hasBookmarked,
 *   bookmark,
 *   unBookmark,
 *   hasViewed,
 *   view,
 *   hits,
 *   logHit,
 * } = useContext(StoryComponentContext)
 *
 */
export const StoryComponentProvider = ({
  children,
  story,
  isVisible,
  preview,
  disableAnalytics = false,
}: {
  children: React.ReactNode
  story: Story
  isVisible?: boolean
  preview?: boolean
  disableAnalytics?: boolean
}) => {
  if (typeof isVisible === 'undefined') {
    isVisible = false
  }
  if (typeof preview === 'undefined') {
    preview = false
  }
  const {user} = useContext(AuthenticationContext)
  /**
   * Initialize state for the story
   */
  const url = getStoryUrl(story.dateSlug, story.slug.current)

  /**
   * Initialize state for the story's user-based analytics
   */
  const [hasLiked, setHasLiked] = useState(false)
  const [hasBookmarked, setHasBookmarked] = useState(false)
  const [hasViewed, setHasViewed] = useState(false)
  const [hits, setHits] = useState(0)

  /**
   * On mount, get the story's user-based analytics
   */
  trpc.analytics.getAnalytics.useQuery(
    {sanityId: story._id},
    {
      refetchOnWindowFocus: false,
      enabled: !!user,
      onSettled: (data) => {
        if (!data) return
        setHasLiked(data.liked)
        setHasBookmarked(data.bookmarked)
        setHasViewed(data.viewed)
      },
    },
  )

  /**
   * Consumers can use `hit()` to log a hit count for a story manually.
   * Default behavior is for this to be called on mount.
   */
  const {mutate: logHit} = trpc.analytics.logHitCount.useMutation({
    onSettled: (data) => {
      setHits(data || 0)
    },
  })
  const hit = useCallback(() => {
    if (disableAnalytics) return
    logHit({sanityId: story._id, sanityType: 'story'})
  }, [disableAnalytics, logHit, story._id])

  /**
   * Log hit on mount
   */
  useEffect(() => {
    hit()
  }, [hit])

  /**
   * Consumers can use `view()` to log a story as viewed by the user
   */
  const {mutate: logView} = trpc.analytics.logView.useMutation()
  const view = useCallback(() => {
    if (disableAnalytics || !user) return
    setHasViewed(true)
    logView({
      sanityId: story._id,
      // Send story metadata so we can use it downstream in the golden realms
      // without having to fetch it again there.
      title: story.title,
      category: story.category,
      tags: story.tags?.map((tag) => tag.name),
      organizations: story.organizations?.map((org) => org.name),
    })
  }, [disableAnalytics, user, logView, story])

  /**
   * Log view on mount
   */
  useEffect(() => {
    if (user) {
      view()
    }
  }, [view, user])

  /**
   * Consumers can use `bookmark()` to bookmark a story
   */
  const {mutate: logBookmark} = trpc.analytics.logBookmark.useMutation()
  const bookmark = useCallback(() => {
    if (user) {
      setHasBookmarked(true)
      logBookmark({sanityId: story._id})
    }
  }, [user, logBookmark, setHasBookmarked, story._id])

  /**
   * Consumers can use `unBookmark()` to unbookmark a story
   */
  const {mutate: logUnbookmark} = trpc.analytics.unlogBookmark.useMutation()
  const unBookmark = useCallback(() => {
    if (user) {
      setHasBookmarked(false)
      logUnbookmark({sanityId: story._id})
    }
  }, [user, logUnbookmark, setHasBookmarked, story._id])

  /**
   * Consumers can use `like()` to like a story
   */
  const {mutate: logLike} = trpc.analytics.logLike.useMutation()
  const like = useCallback(() => {
    if (user) {
      setHasLiked(true)
      logLike({sanityId: story._id})
    }
  }, [user, setHasLiked, logLike, story._id])

  /**
   * Consumers can use `unLike()` to unlike a story
   */
  const {mutate: logUnlike} = trpc.analytics.unlogLike.useMutation()
  const unLike = useCallback(() => {
    if (user) {
      setHasLiked(false)
      logUnlike({sanityId: story._id})
    }
  }, [user, logUnlike, setHasLiked, story._id])

  /**
   * Wrap children in the context provider
   */
  return (
    <StoryComponentContext.Provider
      value={{
        story,
        isVisible,
        url,
        hasBookmarked,
        bookmark,
        unBookmark,
        hasLiked,
        like,
        unLike,
        hasViewed,
        view,
        preview,
        hits,
        hit,
      }}
    >
      {children}
    </StoryComponentContext.Provider>
  )
}

const StoryComponentContextSchema = z.object({
  story: StorySchema,
  isVisible: z.boolean(),
  preview: z.boolean().optional(),
  url: z.string(),
  hasLiked: z.boolean(),
  like: z.function(),
  unLike: z.function(),
  hasBookmarked: z.boolean(),
  bookmark: z.function(),
  unBookmark: z.function(),
  hasViewed: z.boolean(),
  view: z.function(),
  hits: z.number(),
  hit: z.function(),
})
export const StoryComponentContext = createContext<
  z.infer<typeof StoryComponentContextSchema>
>({
  story: {} as Story,
  preview: false,
  isVisible: false,
  url: '',
  hasLiked: false,
  like: () => void 0,
  unLike: () => void 0,
  hasBookmarked: false,
  bookmark: () => void 0,
  unBookmark: () => void 0,
  hasViewed: false,
  view: () => void 0,
  hits: 0,
  hit: () => void 0,
})
